<template>
    <div  v-wechat-title="$route.meta.title">      
        <template>
            <BackTop :height="100" :bottom="60" >
                <div class="topback"><Icon type="ios-arrow-up" class="topfont"/>{{fldObj['mm_top']}}</div>
            </BackTop>
        </template>
        <el-container >  
            <fixRight ref="refright"/>
            <!-- <el-header :style="{position: 'fixed', width: '100%','z-index':'999',height:'100px'}">
                 <mainHead @child-menuSelect="childClick" @child-power="childPower" ref="headbar"></mainHead>
            </el-header> -->
            <el-main :style=" {'margin-bottom':'70px', width:'1270px','margin-left':'auto','margin-right':'auto'} "  >          
                <div style="font-size:10px; ">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item><el-link @click="homePage" :underline="false"> {{fldObj['mc_home']}}</el-link></el-breadcrumb-item>
                        <el-breadcrumb-item  >{{fldObj['mc_pur']}}：</el-breadcrumb-item>
                        <el-breadcrumb-item  >{{pono}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div> 
                <div style=" width:1000px;margin:0px auto;margin-top:40px;">
                    <el-steps :active="active" align-center finish-status="success" style="font-size:18px;">
                        <el-step :title="fldObj['mc_submit']" >
                            <template slot="description" >
                                <span style="color:#909399;font-size:11px;">{{adddttm}}</span>
                            </template>
                        </el-step>
                        <el-step :title="fldObj['mc_submit']"  >
                            <template slot="description" >
                                <span style="color:#909399;font-size:11px;">{{paystfg}}</span>
                            </template>
                        </el-step>
                        <el-step :title="fldObj['mc_prod']"  >
 
                        </el-step>
                        <el-step :title="fldObj['mc_stk']"  >
                            <template slot="description" >
                                <span style="color:#909399;font-size:11px;">{{shipstfg}}</span>
                            </template>
                        </el-step>
                        <el-step :title="fldObj['mc_receipt']" ></el-step>
                    </el-steps>
                </div>
                
                <div style="margin-top:20px;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;display:flex">
                    <div style="margin-left:10px;color:#F56C6C; font-size:14px;text-align:left;width:300px; ">
                        {{fldObj['mc_stfg']}}：{{LANG=='CN'?ordObj['STFGNM']:ordObj['ESTFGNM']}}
                    </div>
                    <div style="width:910px;text-align:right;">
                        <el-button size="mini" type="primary" plain style="margin-right:30px">{{fldObj['mc_buy']}}</el-button>
                        <el-button v-print="'#printMe'"  size="mini" type="primary" plain> {{fldObj['mc_print']}}</el-button>
                    </div>
                </div>
                <div id="printMe">     
                    <!-- 基本信息 -->
                    <div style="margin-top:20px; line-height:40px;height:40px;  "> 
                        <div style=" color:#606266; font-size:14px;text-align:left;">
                            <i class="iconfont icon-xuanze1" style="color:#BCC6CC;margin-right:5px;"></i>{{fldObj['mc_basic']}}
                        </div>
                    </div>
                    <div style="margin-top:5px; line-height:40px;height:40px;border:1px solid #ccc;display:flex;" class="bkcolor"> 
                        <div style="width:250px;" class="rowcss">{{fldObj['mc_order']}}</div>
                        <div style="width:250px;" class="rowcss">{{fldObj['mc_time']}}</div>
                        <div style="width:250px;" class="rowcss">{{fldObj['mc_payment']}}</div>
                        <div style="width:250px;" class="rowcss">{{fldObj['mc_delivery']}}</div>
                        <div style="width:250px;" class="noborder"> {{fldObj['mc_ship']}}</div>
                    </div>
                    <div style="min-height:40px;line-height:40px; display:flex;" class="notop"> 
                        <div style="width:250px;" class="rowcss">{{pono}}</div>
                        <div style="width:250px;" class="rowcss">{{adddttm}}</div>
                        <div style="width:250px;" class="rowcss">{{ordObj['PAYMTHNM']}}</div>
                        <div style="width:250px;" class="rowcss">{{ordObj['SHPMTH']}}</div>
                        <div style="width:250px;" class="noborder">---</div>
                    </div>
                    <div style="line-height:40px;display:flex; " class="notop bkcolor"> 
                        <div style="width:250px;" class="rowcss">{{fldObj['mc_po']}}</div>
                        <div style="width:1000px;" class="noborder">{{fldObj['mc_rmk']}}</div>
                    </div>
                    <div style="min-height:40px;line-height:40px;display:flex;" class="notop"> 
                        <div style="width:250px;" class="rowcss">---</div>
                        <div style="width:1000px;" class="noborder">{{ordObj['RMK']}}</div>
                    </div>
                    <!-- 收货人信息 -->
                    <div style="margin-top:15px; line-height:40px;height:40px;  "> 
                        <div style=" color:#606266; font-size:14px;text-align:left;">
                            <i class="iconfont icon-xuanze1" style="color:#BCC6CC;margin-right:5px;"></i>{{fldObj['mc_consign']}}
                        </div>
                    </div>
                    <div style="margin-top:5px; line-height:40px;height:40px;border:1px solid #ccc;display:flex;" class="bkcolor"> 
                        <div style="width:200px;" class="rowcss"> {{fldObj['mc_rcver']}}</div>
                        <div style="width:200px;" class="rowcss"> {{fldObj['mc_phone']}}</div>
                        <div style="width:850px;" class="noborder"> {{fldObj['mc_addr']}}</div>
                    </div>
                    <div style=" line-height:40px;min-height:40px;display:flex;background:#Fff " class="notop"> 
                        <div style="width:200px;" class="rowcss">{{ordObj['RCVER']}}</div>
                        <div style="width:200px;" class="rowcss">{{ordObj['RCVTEL']}}</div>
                        <div style="width:850px;" class="noborder">{{ordObj['RCVADDR']}}</div>
                    </div>
                    <!-- 收票人信息 -->
                    <div style="margin-top:15px; line-height:40px;height:40px;  "> 
                        <div style=" color:#606266; font-size:14px;text-align:left;">
                            <i class="iconfont icon-xuanze1" style="color:#BCC6CC;margin-right:5px;"></i>{{fldObj['mc_payee']}}
                        </div>
                    </div>
                    <div style="margin-top:5px; line-height:40px;height:40px;border:1px solid #ccc;display:flex; " class="bkcolor"> 
                        <div style="width:200px;" class="rowcss"> {{fldObj['mc_invoice']}}</div>
                        <div style="width:200px;" class="rowcss">{{fldObj['mc_title']}}</div>
                        <div style="width:200px;" class="rowcss"> {{fldObj['mc_content']}}</div>
                        <div style="width:650px;" class="noborder">{{fldObj['mc_invinfo']}}</div>
                    </div>
                    <div style=" line-height:40px;min-height:40px;display:flex;background:#Fff " class="notop"> 
                        <div style="width:200px;" class="rowcss">{{LANG=='CN'?ordObj['INVTYNM']:ordObj['EINVTYNM']}}</div>
                        <div style="width:200px;" class="rowcss">{{LANG=='CN'?ordObj['INVTY']:ordObj['EINVTY']}}</div>
                        <div style="width:200px;" class="rowcss">{{ordObj['INVTTL']}}</div>
                        <div style="width:650px;" class="noborder">{{ordObj['INVADDR']}}</div>
                    </div>
                    <!-- 商品信息 -->
                    <div style="margin-top:15px; line-height:40px;height:40px;  "> 
                        <div style=" color:#606266; font-size:14px;text-align:left;">
                            <i class="iconfont icon-xuanze1" style="color:#BCC6CC;margin-right:5px;"></i>{{fldObj['mc_product']}}
                        </div>
                    </div>
                    <div style="margin-top:5px; line-height:40px;height:40px;border:1px solid #ccc;display:flex; " class="bkcolor"> 
                        <div style="width:130px;" class="rowcss"> {{fldObj['mc_image']}}</div>
                        <div style="width:210px;" class="rowcss">{{fldObj['mc_prodnm']}}</div>
                        <div style="width:180px;" class="rowcss">{{fldObj['mc_cust']}}</div>
                        <div style="width:140px;" class="rowcss">{{fldObj['mc_manu']}}</div>
                        <div style="width:200px;" class="rowcss"> {{fldObj['mc_prc']}}</div>
                        <div style="width:180px;" class="rowcss"> {{fldObj['mc_shipqty']}}</div>
                        <div style="width:190px;" class="noborder">{{fldObj['mc_subamt']}}</div>
                    </div>
                    <div v-for="(item,index) in ordList" :key="index" >
                        <div v-for="(subitem,subindex) in item.children" :key="subindex" style=" line-height:30px;min-height:60px;display:flex;background:#Fff " class="notop">
                            <div style="width:129px;" class="rowcss">
                                    <el-image :src='subitem.DEFPIC'  fit="fill" style="height:40px;width:50px;margin:10px auto; "  ></el-image>
                            </div>
                            <div style="width:210px;" class="rowcss">
                                <el-link @click="prdList(subitem.PRDIDSEQ,subitem.IDNO)" :underline="false"><span style="border-bottom:1px solid #409eff"> {{LANG=='CN'?subitem.MATNM:subitem.EMATNM}}</span></el-link> 
                            </div>
                            <div style="width:180px;" class="rowcss">{{subitem.CLTMATNO}} </div>
                            <div style="width:140px;" class="rowcss">{{LANG=='CN'?subitem.SUPNM:subitem.ESUPNM}} </div>
                            <div style="width:200px;" class="rowcss">¥{{subitem.PRC}} </div>
                            <div style="width:180px;" class="rowcss"> {{subitem.QTY}} | 0 </div>
                            <div style="width:190px;" class="noborder">¥{{subitem.AMT}} </div>
                        </div>
                    </div>
                    <div style="height:150px;border:1px solid #ccc;display:flex;flex-direction:column"> 
                        <div style="display:flex">
                            <div style="width:850px; " class="sumcss">
                                <span style="float:right"><span style="color:#F56C6C;margin-right:3px;font-weight:600">{{pcsnum}}</span>{{fldObj['mc_total']}}：</span>
                            </div>
                            <div style="width:200px; " class="sumcss">
                                <span style="float:left;margin-left:10px;font-size:14px;">¥{{totalamt}}</span>
                            </div>
                        </div>
                        <div style="display:flex">
                            <div style="width:850px; " class="sumcss">
                                <span style="float:right">{{fldObj['mc_auxfee']}}：</span>
                            </div>
                            <div style="width:200px; " class="sumcss">
                                <span style="float:left;margin-left:10px;font-size:14px;">¥ {{servfee}}</span>
                            </div>
                        </div>
                        <div style="display:flex">
                            <div style="width:850px; " class="sumcss">
                                <span style="float:right">{{fldObj['mc_promo']}}：</span>
                            </div>
                            <div style="width:200px; " class="sumcss">
                                <span style="float:left;margin-left:10px;font-size:14px;">-¥ {{disfee}}</span>
                            </div>
                        </div>
                        <div style="display:flex">
                            <div style="width:850px; " class="sumcss">
                                <span style="float:right;font-weight:600">{{fldObj['mc_ordttl']}}：</span>
                            </div>
                            <div style="width:200px; " class="sumcss">
                                <span style="font-size:14px;float:left;margin-left:10px;color:#F56C6C;font-weight:600">¥ {{actamt}}</span>
                            </div>
                        </div>
                    </div>
                </div> 
            </el-main>
            <el-footer  class='footer-css'>
                <mainFoot ref="pgfoot"/>
            </el-footer>                  
        </el-container>  
    </div>  
</template>
<script>
 
import { paramBase,paramData ,paramData2} from '../../api/Select'
import fixRight from '@/components/forms/fixRight.vue'
import mainHead from '@/components/forms/main_Head.vue'
import mainFoot from '@/components/forms/main_Foot.vue'
export default {
    name:"ordDetails",
    data () {
        return {
            LANG:'',
            pono:'',
            active:1,
            ordList:[],
            totalamt:'', //商品总额
            servfee:'', //物流辅助服务费
            disfee:'', //促销费
            pcsnum:'', //商品件数
            v_username:this.$cookies.get('v_telno'), //api 请求用户
            adddttm:'',
            paystfg:'',//支付状态
            shipstfg:'',//发货状态
            fldObj:{},
            ordObj:{},
        }
    },
    mounted () {          
        //设定子页面语言  
        this.$nextTick(()=>{
            // this.$refs.headbar.toolFlag=false 
            // this.$refs.headbar.v_visible=false 
            // this.$refs.headbar.getField(this.LANG)
            // this.$refs.headbar.chklang=this.LANG
            this.$refs.pgfoot.getField(this.LANG)
            this.$refs.refright.getField(this.LANG)
            this.getField()
        })
        
    },
    computed: {
        actamt:function(){
            return Number(this.totalamt) + Number(this.servfee)- Number(this.disfee )
        }
    },
    components: {
        fixRight,
        //mainHead,
        mainFoot,
    },
    created () {
        //订单记录信息
        this.pono =this.$route.query.p_pono
        this.LANG =this.$route.query.lang
        this.$axios({
            method:'post',
            url: this.$store.state.baseUrl+'auth/login/getBaseData' ,
            data:{ p_key:'idno',p_value:this.pono,p_table:'v_ord' },
        }).then(res =>{
            if (res.data.code==='200'){
                this.ordObj =res.data.result[0]
                //提交订单时间
                this.adddttm =this.ordObj['ADDDTTM']
                if (this.ordObj['STFG']==='payno'||this.ordObj['STFG']==='cancel'){
                    this.active =1   
                    this.paystfg='未支付'
                    this.shipstfg='未发货'
                }else if (this.ordObj['STFG']==='pay' ){
                    this.active =2   
                    this.paystfg='已支付'
                    this.shipstfg='未发货'
                }
            }else{
                this.$alert(res.data.result, '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'warning'
                });
            }
        })
        //订单明细
        this.getOrdList()
    },
    watch: {
 
    },
    methods: {
        //产品详情页
        prdList(idseq,idno){
            let routeUrl = this.$router.resolve({
                path: '/prdList',
                query: { facid:idno,idseq:idseq,lang:this.LANG},
            })
            window.open(routeUrl.href, '_blank');
        },
        homePage(){
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG},
            });
            window.open(routeUrl.href, '_self'); 
        },
        //界面字段语言设置
        getField(){
            let that =this
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'56006',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] =that.LANG=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        //订单明细
        getOrdList(){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getMainSub' ,
                data:{ p_key:'idno',p_value:this.pono,maintab:'v_ord',subtab:'v_orditm' },
            }).then(res =>{
                if (res.data.code==='200'){
                    this.ordList =res.data.result
                    this.pcsnum =this.ordList[0].children.length
                    this.totalamt =this.ordList[0].AMT
                    this.servfee =Number(this.ordList[0].SERVFEE).toFixed(2)
                    this.disfee =Number(this.ordList[0].DISFEE).toFixed(2)
                }else{
                    let routeUrl = this.$router.resolve({
                        path: "/frontLogin",
                        query: {lang:this.$store.state.lang },
                    });
                    window.open(routeUrl.href, '_self');
                }
            })
        },
        //热销品牌事件
        webClick(val){
            if (val)
            // window.location.href=val
            window.open(val,'_blank')
        },
        //菜单事件
        childClick(val){
            console.log('main2:'+val)
 
        },
        childPower(val){
            console.log('mainee:'+val)
 
        },

        goBlank(path,name,param){
            let routeUrl = this.$router.resolve({
                path: path,
                query: {lang:this.LANG,pid:name,param:param},
            });
            if (param==='login'){
                window.open(routeUrl.href, '_self');
            }else if (param==='blank'){
                window.open(routeUrl.href, '_blank');
            }else{
                window.open(routeUrl.href, '_self');
            }
        },
 
 
    }
}
</script>
<style>
    .el-col:hover  {  
        background: #2d8cf0!important; 
    } 
    .el-dropdown-menu{
        top: 103px  !important;
        width:153px !important;
        padding-left:0px !important; 
        margin-right:-48px !important;    
    }
    .el-submenu__title:focus, .el-submenu__title:hover  {  
        background: #dcdee2!important; 
        /* height:40px !important;  */
        width:100% !important;
    }  
    .el-menu-item:hover{  
        background: #2db7f5 !important; 
        color: #fff !important; 
        height:100% !important;  
    }  
    .el-menu-item.is-active {  
        color: #fff !important;  
        background: #409EFF !important;  
    }     
    .el-menu--collapse  {
        width:200px;
        line-height:35px;
    }  
    .el-submenu__title i {
      color: #5cadff;
    }
    .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }  
    .atooltip{
      border: #1480ce 1px solid !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow::after {
      border-right-color: #1480ce !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow{
        border-right-color: #1480ce !important;
    }  
    .ptooltip{
      border: #ec5778 1px solid !important;
    }
    .el-tooltip__popper[x-placement^=top] .popper__arrow::after {
      border-right-color: #ec5778 !important;
    }
    .el-tooltip__popper[x-placement^=top] .popper__arrow{
        border-right-color: #ec5778 !important;
    }     
</style> 
<style scoped lang="less">
    .divcolor{
        background-color: #5cadff;
    }
    .sumcss{
        line-height:35px;
        height:35px;
        font-size:14px;
        color:#606266
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
  .noborder {
      color:#606266; 
      font-size:14px;
      text-align:center;
  }
  .notop{
      border-left:1px solid #ccc;
      border-right:1px solid #ccc;
      border-bottom:1px solid #ccc;
  }
  .img-div{
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
      line-height:20px;
  }
  .rowcss{
      color:#606266; 
      font-size:14px;
      text-align:center;
      border-right:1px solid #ccc;
  }
 
  .footer-css {
        width: 100%;
        position: relative;
        bottom: 0 ;
        background:#f8f8f9;
        height:360px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
    .card-div {
        padding: 14px;
        background:#F2F6FC; 
        text-align:middle;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .topback{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        font-size:12px;
        color: #409EFF;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;
        bottom:275px;
        background-color: rgb(255,255,253);
        cursor:pointer;
        z-index:999999;
    }
    .topfont{
        color:#409EFF;
        font-size:16px;
        // font-weight:600;
    }
    .topback:hover{
        background-color:#2b85e4;
        color:white;
    }
    .bkcolor{
        background:#CAE1FF
    }
</style>